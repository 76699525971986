<template>
    <div>
        <gmap-map
            :center="center"
            :zoom="12"
            :style="style"
            ref="gmapMapObject"
        >
            <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                @click="openInfoWindowTemplate(m)"
            ></gmap-marker>

            <gmap-info-window
                :options="{maxWidth: 300}"
                :position="infoWindow.position"
                :opened="infoWindow.open"
                @closeclick="infoWindow.open=false">
                <div v-html="infoWindow.template"></div>
            </gmap-info-window>

        </gmap-map>


    </div>
</template>

<script>
export default {
    name: "GoogleMap",
    data() {
        return {
            center: { lat: 27.33, lng: -82.44 },
            markers: [],
            places: [],
            currentPlace: null,
            infoWindow: {
                position: {lat: 0, lng: 0},
                open: false,
                template: ''
            }
        };
    },
    props: {
        dealers: Array,
        height: {
            type: String,
            default: '900px'
        },
    },
    created() {
        this.addMarker();
    },
    mounted() {

    },
    computed: {
        style() {
            return `width: 100%; height: ${this.height}`;
        }
    },
    methods: {
        setPlace(place) {
            this.currentPlace = place;
        },
        addMarker() {
            var marker = new Array;
            var center = new Array;

            if (this.dealers.length > 0) {

                for (var i = this.dealers.length - 1; i >= 0; i--) {

                  let dealer_lat = parseFloat(this.dealers[i].latitude);
                  let dealer_lng = parseFloat(this.dealers[i].longitude);

                  if (i == 0) {
                    center = {
                      lat: parseFloat(this.dealers[i].latitude),
                      lng: parseFloat(this.dealers[i].longitude)
                    };
                  }

                  marker = {
                        lat: dealer_lat,
                        lng: dealer_lng
                    };

                    this.markers.push({
                        position: marker,
                        title: this.dealers[i].name,
                        dealer: {
                            address1: this.dealers[i].address1,
                            city: this.dealers[i].city,
                            state: this.dealers[i].state,
                            zip: this.dealers[i].zip,
                            phone: this.dealers[i].phone,
                        }
                    });

                    if (i == 0) {
                      this.fitBoundsWithNative();
                    }
                }

                this.center = center;
            }
        },
        openInfoWindowTemplate (item) {
            this.infoWindow.template = '<h5 style="font-size:12px;">' + item.title + '</h1><p>' + item.dealer.address1 + '<br>' + item.dealer.city + ', ' + item.dealer.state + ' ' + item.dealer.zip + '';
            if (item.dealer.phone !== null) {
                this.infoWindow.template += '<br>' + item.dealer.phone + '</p>';
            }
            this.infoWindow.template += '</p>';

            this.infoWindow.position = item.position;
            this.infoWindow.open = true;
       },
       fitBoundsWithNative() {
        console.log(arguments);
        let tries = 10;
        let interval = 60;
        let _this = this;
        let attempt = () => {
          let ref = _this.$refs.gmapMapObject?.$mapObject;
          if (ref) {
            let bounds = new google.maps.LatLngBounds();
            for (let i = 0; i < _this.markers.length; i++) {
              let marker = _this.markers[i];
              let position = new google.maps.LatLng(marker.position.lat, marker.position.lng);
              bounds.extend(position);
            }
            ref.fitBounds(bounds);
          } else {
            tries--;
            if (tries) {
              setTimeout(attempt, interval);
            }
          }
        };
        setTimeout(attempt, interval);
       }
    }
};
</script>
