<template>
<div id="dealer-locator" class="w-full">
  <div id="dyoRedesign" :class="`font-${brandPrimaryFontFamily}`" :style="`background-color: ${brandBgColor}`">
    <div data-forcestyle="ownResearchSection" class="px-4 md:px-0 mx-auto max-w-6xl" :class="[({'middle-section-ez': brand === 4}), ({'middle-section-wd': brand === 3})]">
      <div class="py-16">
        <div class="text-center md:text-2xl text-xl pb-4" :class="`text-${brandTitleColor} middleSectionTitle_${brandName}`" id="middleSectionTitle">
          <div :class="[({ 'uppercase font-adelle': brand == 2, 'font-mont': brand == 6, 'font-mont': brand == 7, 'font-opensans': brand == 9 }, [1, 6, 7].includes(brand) ? 'capitalize' : 'uppercase'), (brand == 3 || brand === 1 ? '' : 'font-bold')]" :style="brand == 3 ? 'letter-spacing: .1em;' : ''">
            Find an authorized dealer near you
          </div>
        </div>
        <div v-if="[1,2,3,4,6,7,9].indexOf(brand) > -1" :data-forcestyle="`copy-text_${brandName}`" class="own-research-body-copy text-base text-center text-gray-600 md:px-8 pb-6" :class="{ 'font-mont': brand == 6 }">
          We sell exclusively through a dealer network who can help you find the
          right products for your home or property.
        </div>

        <div v-if="[1,2,3,4,6,7,9].indexOf(brand) > -1" :data-forcestyle="`copy-text_${brandName}`" class="own-research-body-copy text-base text-center text-gray-600 md:px-8 pb-6" :class="[(brand == 6 ? 'font-mont' : `font-${brandPrimaryFontFamily}`), (brand == 3 ? 'font-semibold' : 'font-bold')]">
            To find an authorized dealer in your area, please enter your zip code right here
        </div>
        <div v-if="[8].indexOf(brand) > -1" :data-forcestyle="`copy-text_${brandName}`" class="text-base text-center text-gray-600 md:px-8 pb-6" :class="{ 'font-mont': brand == 6 }">
            We sell exclusively through a network of dealers trained to find the right products for your custom needs.
        </div>
        <div v-if="[8].indexOf(brand) > -1" :data-forcestyle="`copy-text_${brandName}`" class="text-base text-center text-gray-600 md:px-8 pb-6" :class="[(brand == 6 ? 'font-mont' : `font-${brandPrimaryFontFamily}`), (brand == 3 ? 'font-semibold' : 'font-bold')]">
            To find an authorized dealer in your area, please enter your zip code.
        </div>

        <div
          :data-forcestyle="`copy-text_${brandName}`"
          class="
            flex flex-wrap
            justify-center
            mt-10
            text-gray-600
          "
        >
          <div class="md:w-1/2 w-full md:pr-2">
            <div class="pb-2.5 own-research-field-label" :class="{ 'text-sm': brand == 4 }">Zip Code</div>
            <div class="mb-6">
              <input
                :id="[4, 7].includes(brand) ? '' : 'dyoZipRedesign'"
                type="text"
                placeholder="Enter your zip code"
                class="border px-2 w-full h-10 pl-2 focus:outline-none"
                :class="`${brand === 4 || brand === 7 ? 'border-ezebreeze rounded focus:shadow-outline-eze' : `border-${brandPrimaryColor} focus:shadow-outline-${brandPrimaryColor}`}`"
                v-model="params.zipcode"
                size="10"
                maxlength="5"
                @input="sanitizeZipCode"
                @focusin="showUseMyLocationButton = true"
                @focusout="hideUseMyLocationButton()"
                autocomplete="off"
                :data-forceStyle="`zipinput_${brandName}`"
              />

              <button
                :data-forcestyle="`useMyLocation_${brandName}`"
                :class="brand === 4 || brand === 7 || brand === 8 ? 'bg-ezeprimary-important focus:shadow-outline-eze rounded' : `bg-${brandPrimaryColor} focus:shadow-outline-${brandPrimaryColor} uppercase`"
                class="py-1 mt-2 px-4 text-white w-full focus:outline-none"
                v-if="showUseMyLocationButton == true"
                @click="useMyLocation"
              >
                Use my location
              </button>
            </div>
          </div>

          <div class="md:w-1/2 w-full md:pl-2">
            <div v-show="show_results === 'yes'">
                <div class="pb-2.5 own-research-field-label" :class="{ 'text-sm': brand == 4 }">From</div>
                <div>
                    <select
                        :data-forcestyle="`fromselect_${brandName}`"
                        v-model="params.distance"
                        name=""
                        :id="[4, 7].includes(brand) ? '' : 'dyoFromRedesign'"
                        class="w-full h-10 border px-2 focus:outline-none"
                        :class="[(brand === 4 || brand === 7 ? 'border-ezebreeze text-ezeprimary focus:shadow-outline-eze' : `text-${brandPrimaryColor} border-${brandPrimaryColor} focus:shadow-outline-${brandPrimaryColor} bg-white`), (brand == 3 ? 'font-semibold' : 'font-bold')]"
                    >
                        <option
                            v-for="(item, key) in this.distances"
                            :key="key"
                            :value="item.id"
                        >
                            {{ item.value }}
                        </option>
                    </select>
                </div>
            </div>
          </div>
        </div>

        <div
          :data-forcestyle="`copy-text_${brandName}`"
          class="
            flex flex-wrap
            justify-center
            mt-7
            text-gray-600
          "
        >
          <div class="w-full mt-4 md:mt-0" id="select-dealer-type">
            <div class="md:pb-6 pb-3" :class="{ 'text-sm': brand == 4 }">
              Select the type of dealer you're looking for
            </div>
          </div>

          <div class="md:w-1/2 w-full" :class="{'uppercase': brand == 1}">
            <div class="pb-3" v-if="!params.international">
              <label class="inline-flex items-center">
                <input
                  type="checkbox"
                  class="focus:outline-none border"
                  :class="brand === 4 || brand === 7 || brand === 8 ? 'eze-form-checkbox focus:shadow-outline-eze border-ezebreeze' :`${brandPrimaryColor}-form-checkbox border-${brandPrimaryColor} focus:shadow-outline-${brandPrimaryColor}`"
                  name=""
                  id=""
                  v-model="params.showroom"
                />
                <span :data-forcestyle="`dealer-checkbox-label-span-${brandName}`" class="cursor-pointer ml-4">Dealers with Showrooms</span>
              </label>
            </div>

            <div class="pb-3">
              <label
                class="inline-flex items-center"
                v-if="!params.international"
              >
                <input
                  type="checkbox"
                  class="focus:outline-none border"
                  :class="brand === 4 || brand === 7 || brand === 8 ? 'eze-form-checkbox focus:shadow-outline-eze border-ezebreeze' :`${brandPrimaryColor}-form-checkbox border-${brandPrimaryColor} focus:shadow-outline-${brandPrimaryColor}`"
                  name=""
                  id=""
                  value="R"
                  v-model="params.primary_segment"
                />
                <span :data-forcestyle="`dealer-checkbox-label-span-${brandName}`" class="cursor-pointer ml-4">Dealers for Homeowners</span>
              </label>
            </div>
          </div>

          <div class="md:w-1/2 w-full" :class="{'uppercase': brand == 1}">
            <div class="pb-3">
              <label
                class="inline-flex items-center md:-mt-3 lg:mt-0"
                v-if="!params.international"
              >
                <input
                  type="checkbox"
                  class="focus:outline-none border"
                  :class="brand === 4 || brand === 7 || brand === 8 ? 'eze-form-checkbox focus:shadow-outline-eze border-ezebreeze' :`${brandPrimaryColor}-form-checkbox border-${brandPrimaryColor} focus:shadow-outline-${brandPrimaryColor}`"
                  name=""
                  id=""
                  value="B"
                  v-model="params.primary_segment"
                />
                <span :data-forcestyle="`dealer-checkbox-label-span-${brandName}`" class="cursor-pointer ml-4"
                >Dealers for Trade/Industry Professionals</span
                >
              </label>
            </div>

            <div class="pb-3" v-if="brandObject.show_international_filter">
              <label class="inline-flex items-center">
                <input
                  type="checkbox"
                  class="focus:outline-none border"
                  :class="brand === 4 || brand === 7 || brand === 8 ? 'eze-form-checkbox focus:shadow-outline-eze border-ezebreeze' :`${brandPrimaryColor}-form-checkbox border-${brandPrimaryColor} focus:shadow-outline-${brandPrimaryColor}`"
                  name=""
                  id=""
                  v-model="params.international"
                />
                <span :data-forcestyle="`dealer-checkbox-label-span-${brandName}`" class="cursor-pointer ml-4">
                  Dealers for International Exports
                </span>
              </label>
            </div>
          </div>

          <div v-if="retailers_enabled == '1'" class="w-full" :class="{'uppercase': brand == 1}">
            <div class="pb-3">
              <label
                class="inline-flex items-center md:-mt-3 lg:mt-0"
                v-if="!params.international"
              >
                <input
                  type="checkbox"
                  class="focus:outline-none border"
                  :class="brand === 4 || brand === 7 || brand === 8 ? 'eze-form-checkbox focus:shadow-outline-eze border-ezebreeze' :`${brandPrimaryColor}-form-checkbox border-${brandPrimaryColor} focus:shadow-outline-${brandPrimaryColor}`"
                  v-model="params.retailers"
                />
                <span :data-forcestyle="`dealer-checkbox-label-span-${brandName}`" class="cursor-pointer ml-4">Lowe's / Retailers</span>
              </label>
            </div>
          </div>

          <div class="w-full mt-10">
            <a
              :data-forcestyle="`submitSearch_${brandName}`"
              href="#"
              @click.prevent="submitSearch()"
              class="
                text-white
                text-center
                py-4
                block
                m-auto
                focus:outline-none
              "
              :class="[(brand === 4 || brand === 7 ? 'rounded-lg bg-ezeprimary focus:shadow-outline-eze submit-search-ez' : `bg-${brandPrimaryColor} focus:shadow-outline-${brandPrimaryColor} uppercase`), ({'font-bold': brand !== 3}), ({'tracking-widest': brand === 3})]"
              :style="(brand === 4 || brand === 7 ? 'text-decoration: none !important;' : '') + (brand === 3 ? 'color: #1d1d1d !important' : 'color: white;')"
            >Submit</a> <!-- TODO: extract -->
          </div>

          <div
            class="results-wrapper clearfix mt-4"
            v-if="show_results === 'loading'"
          >
            <p>Please wait! Fetching your results.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SMALL SCREEN FILTER -->
  <div
    :data-forcestyle="`copy-text_${brandName}`"
    class="
        md:hidden
        flex flex-col
        md:flex-row
        md:items-center
        md:justify-between
        mb-4
        text-center
      "
    :class="brand === 4 || brand === 7 ? `font-${brandPrimaryFontFamily} filters-ez` : `font-${brandPrimaryFontFamily}`"
    :style="`background-color: ${brandBgColor}`"
    v-if="show_results === 'yes' && dealers.length"
  >
    <div class="md:flex">
      <div :data-forcestyle="`dl-showing-filters-title_${brandName}`" class="text-gray-600">Showing</div>
      <div :data-forcestyle="`dl-selected-filter_${brandName}`" :class="[`text-${brandPrimaryColor}`, (brand === 3 ? 'font-semibold' : 'font-bold'), ({'font-mont': brand === 6})]" class="pl-3" v-if="!params.international && params.showroom">Dealers with
        Showrooms</div>
      <div :data-forcestyle="`dl-selected-filter_${brandName}`" :class="[`text-${brandPrimaryColor}`, (brand === 3 ? 'font-semibold' : 'font-bold'), ({'font-mont': brand === 6})]" class="pl-3" v-if="!params.international && params.primary_segment.includes('R')">
        Dealers for Homeowners</div>
      <div :data-forcestyle="`dl-selected-filter_${brandName}`" :class="[`text-${brandPrimaryColor}`, (brand === 3 ? 'font-semibold' : 'font-bold'), ({'font-mont': brand === 6})]" class="pl-3"
          v-if="!params.international && params.primary_segment.includes('B')">Dealers for Trade/Industry
        Professionals</div>
      <div :data-forcestyle="`dl-selected-filter_${brandName}`" :class="[`text-${brandPrimaryColor}`, (brand === 3 ? 'font-semibold' : 'font-bold'), ({'font-mont': brand === 6})]" class="pl-3" v-if="!params.international && params.retailers == true">Lowe's / Retailers</div>
      <div :data-forcestyle="`dl-selected-filter_${brandName}`" :class="[`text-${brandPrimaryColor}`, (brand === 3 ? 'font-semibold' : 'font-bold'), ({'font-mont': brand === 6})]" class="pl-3" v-if="params.international">Dealers for international exports</div>
    </div>
    <div>
      <button :data-forcestyle="`change-criteria_${brandName}`" class="text-gray-600 underline change-criteria-ez" @click="scrollTo('#select-dealer-type')">Change
      </button>
    </div>
  </div>
  <!-- END SMALL SCREEN FILTERS -->

  <!-- MAP-->
  <div v-show="!last_search_params.international" class="w-full" :style="`background-color: ${brandBgColor}`">
    <div
      data-forcestyle="googleMap"
      class="mx-auto max-w-6xl"
      v-if="show_results === 'yes' && dealers.length"
    >
      <google-map :dealers="dealers" height="450px"></google-map>
    </div>
  </div>

  <!---END MAP-->

  <!--SHOWROOMS SECTION--->
  <div class="w-full" :style="`background-color: ${brandBgColor}`" :class="brand === 4 || brand === 7 ? `font-${brandPrimaryFontFamily} filters-ez` : `font-${brandPrimaryFontFamily}`">
    <div data-forcestyle="results" class="mx-auto pb-16 md:pb-10 md:pt-10 max-w-6xl">
      <div
        class="
          wtb-md-hidden
          wtb-hidden
          md:flex
          flex-col
          md:flex-row
          md:items-center
          md:justify-between
          mb-4
          text-center
        "
        :class="brand === 4 || brand === 7 ? 'filters-ez' : ''"
        v-if="show_results === 'yes' && dealers.length"
      >
        <div class="md:flex">
          <div :data-forcestyle="`dl-showing-filters-title_${brandName}`" class="text-gray-600">Showing</div>
          <div :data-forcestyle="`dl-selected-filter_${brandName}`" :class="[`text-${brandPrimaryColor}`, (brand === 3 ? 'font-semibold' : 'font-bold'), ({'font-mont': brand === 6})]" class="pl-3" v-if="!params.international && params.showroom">Dealers with
            Showrooms</div>
          <div :data-forcestyle="`dl-selected-filter_${brandName}`" :class="[`text-${brandPrimaryColor}`, (brand === 3 ? 'font-semibold' : 'font-bold'), ({'font-mont': brand === 6})]" class="pl-3"
              v-if="!params.international && params.primary_segment.includes('R')">Dealers for Homeowners</div>
          <div :data-forcestyle="`dl-selected-filter_${brandName}`" :class="[`text-${brandPrimaryColor}`, (brand === 3 ? 'font-semibold' : 'font-bold'), ({'font-mont': brand === 6})]" class="pl-3"
              v-if="!params.international && params.primary_segment.includes('B')">Dealers for Trade/Industry
            Professionals</div>
          <div :data-forcestyle="`dl-selected-filter_${brandName}`" :class="[`text-${brandPrimaryColor}`, (brand === 3 ? 'font-semibold' : 'font-bold'), ({'font-mont': brand === 6})]" class="pl-3"
               v-if="!params.international && params.retailers == true">Lowe's / Retailers</div>
          <div :data-forcestyle="`dl-selected-filter_${brandName}`" :class="[`text-${brandPrimaryColor}`, (brand === 3 ? 'font-semibold' : 'font-bold'), ({'font-mont': brand === 6})]" class="pl-3" v-if="params.international">Dealers for international exports</div>
        </div>
        <div>
          <button :data-forcestyle="`change-criteria_${brandName}`" :class="brand === 4 || brand === 7 ? 'change-criteria-ez' : ''" class="text-gray-600 underline" @click="scrollTo('#select-dealer-type')">Change</button>
        </div>
      </div>

      <div id="result-container" :class="{
          '': !last_search_params.international,
          'pt-4': last_search_params.international
      }">
        <div v-if="show_results !== 'loading'">
          <div v-if="dealers.length > 0">
            <!-- Desktop dealers -->
            <div v-if="innerWidth >= 1024" style="overflow-y: hidden">
              <div v-if="page !== 1" @click="previousDealersPage()" id="prevSlideArrow" class="w-1/3 flex justify-center pb-2 px-4 mx-auto">
                <button :data-forcestyle="`slideButton_${brandName}`" class="mx-auto border-b-2 w-full relative" :class="`text-${brandPrimaryColor}`">
                  <span class="see-more-text">See more</span>
                  <!-- Arrow up -->
                  <svg class="w-8 h-8 mx-auto absolute" style="right: 10px; top: 50%; transform: translateY(-50%);" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path>
                  </svg>
                </button>
              </div>
              <transition-group :css="false" @enter="enter" @leave="leave">
                <div v-for="(dealer, index) in displayedDealers" :key="dealer.id" data-name="desktopDealer" :data-forcestyle="`single-dealer-container_${brandName}`">
                <!-- Md and up -->
                <div
                  :data-forcestyle="`desktop-single-dealer_${brandName}`"
                  class="xl:px-8 px-4 py-8"
                  :class="{
                  'bg-gray-50': index % 2 === 0 && brand !== 9 ,
                  'bg-white': index % 2 !== 0,
                }"
                >
                  <div :class="{'font-bold': brand !== 3}" class="mb-4 dealer-list-name">
                    <a
                      :data-forcestyle="`single-dealer-name_${brandName}`"
                      v-if="dealer.landing_enabled == 1"
                      :href="
                        brand_subdomain_prefix +
                        '/landing/' +
                        (dealer.subdomain ? dealer.subdomain : dealer.id)
                      "
                      :class="brand === 4 || brand === 7 ? 'text-ezeprimary' : `text-${brandPrimaryColor}`"
                      target="_blank"
                    >
                      {{ dealer.name }}

                    </a>
                    <span :data-forcestyle="`single-dealer-name_${brandName}`" :class="brand === 4 || brand === 7 ? 'text-ezeprimary' : `text-${brandPrimaryColor}`" v-else>{{ dealer.name }}</span>
                    <span v-if="dealer.distance && !params.international" class="text-sm text-gray-600" style="font-weight: normal;">({{ dealer.distance.toFixed(1) }} Miles Away)</span>
                </div>
                  <div class="flex justify-between flex-row text-left">
                    <div class="w-2/5">
                      <!-- Dealer address -->
                      <div :data-forcestyle="`dealer-address_${brandName}`" class="flex items-start md:justify-start justify-center dealer-address">
                        <svg-location class="h-4 w-4 mr-2" :class="brand !== 7 ? `text-${brandPrimaryColor}` : ''" />
                        <a :data-name="dealer.name"
                           :href="
                              mapSelector +
                              '://maps.google.com/maps?q=' +
                              dealer.address1 +
                              ' ' +
                              dealer.city +
                              ' ' +
                              dealer.state +
                              ' ' +
                              dealer.zip
                            "
                           class="uppercase"
                           :style="brand !== 4 || brand !== 7 ? 'text-decoration: underline;  text-decoration-color: #B4B4B4;' : ''"
                           target="_blank">
                          <div class="w-full text-sm text-gray-600 flex">
                            <span class="ml-1" :data-forcestyle="`single-dealer-address_${brandName}`">{{ dealer.address1 }}</span>
                          </div>
                          <div v-if="dealer.address2" class="w-full text-sm text-gray-600">
                            <span class="ml-1" :data-forcestyle="`single-dealer-address_${brandName}`">{{ dealer.address2 }}</span>
                          </div>
                          <div class="w-full text-sm text-gray-600 mb-4">
                            <span class="ml-1" :data-forcestyle="`single-dealer-address_${brandName}`">{{ dealer.city }}, {{ dealer.state }} {{ dealer.zip }}</span>
                          </div>
                        </a>
                      </div>
                      <div :data-forcestyle="`single-dealer-phone_${brandName}`" class="flex align-middle md:justify-start justify-center dealer-phone">
                        <svg-phone class="h-4 w-4 mr-3" :class="brand !== 7 ? `text-${brandPrimaryColor}` : ''" />
                        <span :class="[(brand === 4 || brand === 7 ? 'text-ezeprimary' : `text-${brandPrimaryColor}`), ({'font-bold': brand !== 3})]">
                          <a
                            :data-forcestyle="`single-dealer-phone-link_${brandName}`"
                            :href="
                              dealer.call_rail_number
                                ? 'tel:' + dealer.call_rail_number
                                : 'tel:' + dealer.phone
                            "
                          >
                            {{
                              dealer.call_rail_number
                                ? dealer.call_rail_number
                                : dealer.phone
                            }}
                          </a>
                        </span>
                      </div>
                      <p :data-forcestyle="`dealer-speaks-spanish_${brandName}`" v-if="dealer.speaks_spanish" :class="[(brand === 4 || brand === 7 ? 'text-ezeprimary' : `text-${brandSecondaryColor} dealer_spanish`)]" :style="brand === 3 ? 'font-weight: 600 !important;' : ''" class="font-semibold italic text-sm mt-4">Hablamos Español</p>
                    </div>
                    <div class="md:text-right md:w-3/5 text-center">
                      <div v-if="dealer.finance"
                           class="flex md:justify-end justify-center mb-6"
                      >
                        <div class="w-48 flex justify-center">
                          <img
                            :src="url_prepend + '/images/PGT_Financing_Badge.gif'"
                            class="finance_badge"
                            v-if="brand == 1"
                          />
                          <img
                            :src="url_prepend + '/images/CGI_Financing_Badge.gif'"
                            class="finance_badge"
                            v-if="brand == 2"
                          />
                          <img
                            :src="url_prepend + '/images/WinDoor_Financing_Badge.gif'"
                            class="finance_badge"
                            v-if="brand == 3"
                          />
                          <img
                            :src="url_prepend + '/images/EzeBreeze_Financing_Badge.gif'"
                            class="finance_badge"
                            v-if="brand == 4"
                          />
                          <img
                            :src="url_prepend + '/images/Eco_Financing_Badge.gif'"
                            class="finance_badge"
                            v-if="brand == 6"
                          />
                          <img
                            :src="url_prepend + '/assets/images/anlin/companylogo.png'"
                            class="finance_badge anlin-badge"
                            v-if="brand == 7" />
                          <img
                            :src="url_prepend + '/assets/images/anlin/companylogo.png'"
                            class="finance_badge anlin-badge"
                            v-if="brand == 8" />
                          <img
                            :src="url_prepend + '/images/Martin_VIP_badge.png'"
                            class="finance_badge martin-badge"
                            v-if="brand == 9" />
                        </div>
                      </div>
                      <div class="w-full justify-end" :class="{'flex': true }">
                        <div v-show="dealer.chat_enabled === 1" class="message-buttons-mobile rounded">
                          <a
                            v-if="dealer.is_chat_online === true"
                            :data-forcestyle="`single-dealer-chat-button_${brandName}`"
                            :data-name="dealer.name"
                            class="
                              leading-9
                              py-2
                              text-center
                              border
                              inline-block
                              ml-4
                              w-48
                              live-chat
                              send-message-button
                            "
                            :class="[(brand === 4 || brand === 7 ? 'border-blue-800 text-ezeprimary rounded' : `border-${brandPrimaryColor} uppercase`), (brand !== 3 ? 'px-8' : 'px-2')]"
                            target="_blank"
                            :href="
                                brand_subdomain_prefix +
                                '/landing/' +
                                (dealer.subdomain ? dealer.subdomain : dealer.id) +
                                '?brand=' +
                                brand +
                                '&open'
                              "
                          >
                            <div :class="brand !== 3 ? `text-${brandPrimaryColor}` : `text-${brandSecondaryColor} text-sm`" :style="brand === 3 ? 'letter-spacing: .3em !important; white-space: nowrap;' : 'white-space: nowrap;'">
                              Live Chat
                            </div>
                          </a>
                          <a
                            v-else
                            :data-forcestyle="`single-dealer-chat-button_${brandName}`"
                            :data-name="dealer.name"
                            class="
                              leading-9
                              py-2
                              text-center
                              border
                              inline-block
                              w-48
                              ml-4
                              send-message
                              send-message-button
                            "
                            :class="[(brand === 4 || brand === 7 ? 'border-blue-800 text-ezeprimary rounded' : `border-${brandPrimaryColor} uppercase`), (brand !== 3 ? 'px-8' : 'px-2')]"
                            target="_blank"
                            :href="
                              brand_subdomain_prefix +
                              '/landing/' +
                              (dealer.subdomain ? dealer.subdomain : dealer.id) +
                              '?brand=' +
                              brand
                            "
                          >
                            <div :class="brand !== 3 ? `text-${brandPrimaryColor}` : `text-${brandSecondaryColor} text-sm`" :style="brand === 3 ? 'letter-spacing: .3em !important; white-space: nowrap;' : 'white-space: nowrap;'">
                              Send Message
                            </div>
                          </a>
                        </div>
                        <button
                          :data-name="dealer.name"
                          :data-forcestyle="`single-dealer-get-quote-button_${brandName}`"
                          v-if="dealer.show_quote_button && brandShowGetQuoteButtonEnabled"
                          @click="showQuotePopup(dealer)"
                          class="
                            leading-9
                            py-1
                            text-center
                            border
                            text-white
                            inline-block
                            w-48
                            ml-4
                            get-quote
                          "
                          :class="[(brand === 4 || brand === 7 ? 'bg-ezeprimary-important rounded' : `border-${brandPrimaryColor} bg-${brandPrimaryColor} uppercase`), (brand !== 3 ? '' : 'text-sm')]"
                          :style="brand === 3 ? 'letter-spacing: .3em !important; white-space: nowrap;' : 'white-space: nowrap;'"
                        >
                          Get Quote
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </transition-group>
              <div @click="nextDealersPage" v-if="page < pages.length" id="nextSlideArrow"
                   class="w-1/3 flex justify-center pt-2 px-4 mx-auto">
                <button :data-forcestyle="`slideButton_${brandName}`" class="mx-auto border-t-2 w-full relative" :class="`text-${brandPrimaryColor}`">
                  <span class="see-more-text">See more</span>
                  <!-- Arrow down -->
                  <svg class="w-8 h-8 mx-auto absolute" style="right: 10px; top: 50%; transform: translateY(-50%);" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                  </svg>
                </button>
              </div>
            </div>
            <!-- End Desktop dealers -->

            <!-- Mobile dealers -->
            <div v-else class="mt-4" id="mobileDealerPager">
              <div v-if="page !== 1" @click="previousDealersPage()" id="prevSlideArrow" class="w-full flex justify-center pb-2 px-4">
                <button :data-forcestyle="`slideButton_${brandName}`" class="mx-auto border-b-2 w-full relative" :class="`text-${brandPrimaryColor}`">
                  <span class="see-more-text">See more</span>
                  <svg class="w-10 h-10 mx-auto absolute" style="right: 10px; top: 50%; transform: translateY(-50%);" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path>
                  </svg>
                </button>
              </div>
              <transition-group :css="false" @enter="enter" @leave="leave">
                <div v-for="(dealer, index) in displayedDealers" :key="`dealer_${index}_${dealer.id}`" ref="content" :data-forcestyle="`single-dealer-container_${brandName}`">
                  <div
                    class="px-4 py-8 dealer-listing-dealer flex flex-col space-y-6"
                    :class="{'bg-gray-50': index % 2 === 0 && brand !== 9, 'bg-white': index % 2 !== 0,}"
                  >
                    <div class="flex justify-start md:justify-center">
                      <div :class="[`text-${brandPrimaryColor}`, (brand !== 3 ? 'font-bold': '')]" class="dealer-list-name text-lg">
                        <a
                          :data-forcestyle="`single-dealer-name_${brandName}`"
                          v-if="dealer.landing_enabled == 1"
                          :href="
                          brand_subdomain_prefix +
                          '/landing/' +
                          (dealer.subdomain ? dealer.subdomain : dealer.id)
                        "
                          target="_blank"
                        >
                          {{ dealer.name }}
                        </a>
                        <span :data-forcestyle="`single-dealer-name_${brandName}`" v-else>{{ dealer.name }}</span>

                        <span v-if="dealer.distance && !params.international" class="text-sm text-gray-600" style="font-weight: normal;">({{ dealer.distance.toFixed(1) }}
                        Miles Away)</span>
                      </div>
                    </div>

                    <!-- Location -->
                    <div class="flex justify-start md:justify-center items-center">
                      <a
                        :data-forcestyle="`dealer-address_${brandName}`"
                        class="flex align-middle text-lg"
                        style="text-decoration: underline;  text-decoration-color: #B4B4B4;"
                        :data-name="dealer.name"
                        :href="
                            mapSelector +
                            '://maps.google.com/maps?q=' +
                            dealer.address1 +
                            ' ' +
                            dealer.city +
                            ' ' +
                            dealer.state +
                            ' ' +
                            dealer.zip
                          "
                        target="_blank"
                      >
                        <svg-location class="h-4 w-4 mr-3" :class="brand !== 7 ? `text-${brandPrimaryColor}` : ''" />
                        <div>
                          <div class="w-full text-gray-600 uppercase ml-2" :data-forcestyle="`single-dealer-address_${brandName}`">
                            {{ dealer.address1 }}
                          </div>
                          <div v-if="dealer.address2" class="w-full text-gray-600 uppercase ml-2" :data-forcestyle="`single-dealer-address_${brandName}`">
                            {{ dealer.address2 }}
                          </div>
                          <div class="w-full text-gray-600 uppercase ml-2" :data-forcestyle="`single-dealer-address_${brandName}`">
                            {{ dealer.city }}, {{ dealer.state }} {{ dealer.zip }}
                          </div>
                        </div>
                      </a>
                    </div>
                    <!-- End Location -->

                    <!-- Get Quote button and Send Message / Live chat buttons-->
                    <div v-if="dealer.is_chat_online === true || dealer.show_quote_button"
                         class="flex justify-between items-center message-buttons-mobile rounded" :class="{'space-x-2': dealer.chat_enabled === 1 }">
                      <div class="w-full message-buttons-mobile" v-if="dealer.chat_enabled === 1">
                        <a
                          v-if="dealer.is_chat_online === true"
                          :data-forcestyle="`single-dealer-chat-button_${brandName}`"
                          :data-name="dealer.name"
                          class="
                            leading-9
                            py-2
                            text-center
                            border
                            inline-block
                            w-full
                            text-sm
                            send-message-button
                          "
                          :class="brand === 4 || brand === 7 ? 'border-blue-800 rounded' : `text-${brandPrimaryColor} border-${brandPrimaryColor} uppercase`"
                          target="_blank"
                          :href="
                            brand_subdomain_prefix +
                            '/landing/' +
                            (dealer.subdomain ? dealer.subdomain : dealer.id) +
                            '?brand=' +
                            brand +
                            '&open'
                          "
                        >
                          <div :class="brand !== 3 ? `text-${brandPrimaryColor}` : `text-${brandSecondaryColor} text-sm`" :style="brand === 3 ? 'letter-spacing: .3em !important; white-space: nowrap;' : 'white-space: nowrap;'">
                            Live Chat
                          </div>
                        </a>
                        <a
                          v-else
                          :data-forcestyle="`single-dealer-chat-button_${brandName}`"
                          :data-name="dealer.name"
                          class="
                            leading-9
                            py-2
                            text-center
                            border
                            inline-block
                            w-full
                            text-sm
                            send-message-button
                          "
                          :class="brand === 4 || brand === 7 ? 'border-blue-800 rounded' : `text-${brandPrimaryColor} border-${brandPrimaryColor} uppercase`"
                          target="_blank"
                          :href="
                            brand_subdomain_prefix +
                            '/landing/' +
                            (dealer.subdomain ? dealer.subdomain : dealer.id) +
                            '?brand=' +
                            brand
                           "
                        >
                          <div :class="brand !== 3 ? `text-${brandPrimaryColor}` : `text-${brandSecondaryColor} text-sm`" :style="brand === 3 ? 'letter-spacing: .3em !important; white-space: nowrap;' : 'white-space: nowrap;'">
                            Send Message
                          </div>
                        </a>
                      </div>

                      <button
                        :data-forcestyle="`single-dealer-get-quote-button_${brandName}`"
                        v-if="dealer.show_quote_button && brandShowGetQuoteButtonEnabled"
                        :data-name="dealer.name"
                        @click="showQuotePopup(dealer)"
                        class="
                          leading-9
                          py-1
                          text-center
                          border
                          text-white
                          inline-block
                          w-full
                          text-sm
                          get-quote-button-ez
                        "
                        :class="[(brand === 4 || brand === 7 ? 'bg-ezeprimary-important rounded' : `border-${brandPrimaryColor} bg-${brandPrimaryColor} uppercase`)]"
                        :style="brand === 3 ? 'letter-spacing: .3em !important; white-space: nowrap;' : 'white-space: nowrap;'"
                      >
                        Get Quote
                      </button>
                    </div>


                    <!-- Phone Button -->
                    <a
                      :data-forcestyle="`single-dealer-phone_${brandName}`"
                      class="
                          leading-9
                          py-1
                          text-center
                          border
                          flex
                          align-middle
                          items-center
                          justify-center
                          w-full
                          phone-button-mobile
                        "
                      :class="brand === 4 || brand === 7 ? 'border-blue-800 rounded' : `text-${brandPrimaryColor} border-${brandPrimaryColor} `"
                      :href="dealer.call_rail_number ? 'tel:' + dealer.call_rail_number : 'tel:' + dealer.phone"
                    >
                      <svg-phone class="mr-3 flex items-center" :class="brand !== 7 ? `text-${brandPrimaryColor}` : ''" />
                      <div :class="brand === 4 || brand === 7 ? 'text-ezeprimary' : `text-${brandPrimaryColor}`"  class="font-semibold">{{
                          dealer.call_rail_number ? dealer.call_rail_number : dealer.phone
                        }}
                      </div>
                    </a>


                    <div v-if="dealer.finance || dealer.speaks_spanish" class="flex justify-between items-center mt-4">
                      <div v-if="dealer.finance" class="flex justify-center items-center"
                           :class="{ 'w-full': !dealer.speaks_spanish }">
                        <img
                          :src="url_prepend + '/images/PGT_Financing_Badge.gif'"
                          class="finance_badge"
                          v-if="brand == 1"
                        />
                        <img
                          :src="url_prepend + '/images/CGI_Financing_Badge.gif'"
                          class="finance_badge"
                          v-if="brand == 2"
                        />
                        <img
                          :src="url_prepend + '/images/WinDoor_Financing_Badge.gif'"
                          class="finance_badge"
                          v-if="brand == 3"
                        />
                        <img
                          :src="url_prepend + '/images/EzeBreeze_Financing_Badge.gif'"
                          class="finance_badge"
                          v-if="brand == 4"
                        />
                        <img
                          :src="url_prepend + '/images/Eco_Financing_Badge.gif'"
                          class="finance_badge"
                          v-if="brand == 6"
                        />
                        <img
                          :src="url_prepend + '/assets/images/anlin/companylogo.png'"
                          class="finance_badge anlin-badge"
                          v-if="brand == 7" />
                        <img
                          :src="url_prepend + '/assets/images/anlin/companylogo.png'"
                          class="finance_badge anlin-badge"
                          v-if="brand == 8" />
                        <img
                          :src="url_prepend + '/images/Martin_VIP_badge.png'"
                          class="finance_badge martin-badge"
                          v-if="brand == 9" />
                      </div>

                      <div v-if="dealer.speaks_spanish" class="flex justify-center items-center text-center"
                           :class="{ 'w-full': !dealer.finance }">
                        <p :data-forcestyle="`dealer-speaks-spanish_${brandName}`"
                           :class="brand === 4 || brand === 7 ? 'text-ezeprimary' : `text-${brandSecondaryColor}`"
                           class="font-semibold italic text-sm">Hablamos Español!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </transition-group>
              <div @click="nextDealersPage" v-if="page < pages.length" id="nextSlideArrow"
                   class="w-full flex justify-center pt-2 px-4">
                <button :data-forcestyle="`slideButton_${brandName}`" class="mx-auto border-t-2 w-full relative" :class="`text-${brandPrimaryColor}`">
                  <span class="see-more-text">See more</span>
                  <svg class="w-10 h-10 mx-auto absolute" style="right: 10px; top: 50%; transform: translateY(-50%);" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                  </svg>
                </button>
              </div>
            </div>
            <!-- End Mobile Dealers -->
          </div>

          <div v-if="no_results" class="-mt-10">
            <div :data-forcestyle="`no-results-header_${brandName}`" class="text-xl text-center pb-6" :class="[`text-${brandPrimaryColor}`, (brand !== 3 ? 'font-bold' : 'font-semibold')]">
              A dealer is not available in this area. Please complete one of the
              following steps to locate a dealer:
            </div>
            <ol :data-forcestyle="`copy-text_${brandName}`"  class="text-center m-auto text-gray-600">
                <li>1. Try using a different zip code.</li>
                <li>2. Select additional dealer types.</li>
                <li><span style="cursor:pointer; text-decoration: underline;" @click.prevent="scrollToTop()">3. Fill out our form, and a dealer will contact you directly.</span></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import GoogleMap from "./GoogleMap";
import SvgLocation from "./SvgLocation";
import SvgPhone from "./SvgPhone";
import EzeBreezeQuoteFormVue from './EzeBreezeQuoteForm.vue';
import MartinQuoteForm from './MartinQuoteForm.vue';
import QuoteStepFormRedesign from './QuoteStepFormRedesign';
import QuoteStepForm from './QuoteStepForm';
import { gsap } from 'gsap';
import { Power3 } from 'gsap';

export default {
  name: "DealerLocator",

  components: {
    GoogleMap,
    SvgLocation,
    SvgPhone,
  },

  props: {
    brand: {
      type: Number,
      required: true,
    },
    brandObject: {
      type: Object,
      required: true,
    },
    brand_ga: '',
    business_types: '',
    client_ip: '',
    countries: '',
    default_country: '',
    door_amount: '',
    frontend_url: '',
    home_types: '',
    homes_built_per_year: '',
    iframe_domain: '',
    interested_financing: '',
    interested_products: '',
    lead_types: '',
    new_buy_plans: '',
    project_conditions: '',
    project_types: '',
    redirect_thankyou: '',
    service_types: '',
    states: '',
    user_agent: '',
    window_amount: '',
    window_importance: '',
    show_close: true,
    brand_subdomain: "",
    retailers_enabled: false,
  },

  data() {
    return {
      dealers: [],
      mapSelector: "https",
      show_results: "none",
      show_results_loading: false,
      show_distance: 0,
      search_status: 0,
      data_form: "shared",
      params: {
        zipcode: "",
        distance: this.distanceOptions()[0].id,
        latitude: 0.0,
        longitude: 0.0,
        type: 1,
        showroom: false,
        primary_segment: ["R"],
        brand: this.brandObject.id,
        international: false,
        retailers: false,
      },
      no_results: false,
      distances: this.distanceOptions(),
      has_errors: false,
      domain: process.env.MIX_APP_DOMAIN,
      userLocation: "" /* this.getUserZipCode() */,
      showUseMyLocationButton: false,
      innerWidth: null,
      maxHeight: 900,
      page: 1,
      pages: [],
      last_search_params: {},
      currentDistanceIndex: 0
    };
  },

  mounted() {
    if (
      navigator.platform.indexOf("iPhone") != -1 ||
      navigator.platform.indexOf("iPad") != -1
    ) {
      this.mapSelector = "maps";
    }
    this.initGoogleApi();

    this.innerWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.innerWidth = window.innerWidth
    })
  },

  computed: {
    displayedDealers() {
      return this.paginate(this.dealers);
    },
    url_prepend() {
      return this.frontend_url ? this.frontend_url : "";
    },
    brand_subdomain_prefix() {
      return this.brand_subdomain ? this.brand_subdomain : "";
    },
    brandName() {
      if (this.brandObject.id === 2) return 'CGI';
      if (this.brandObject.id === 4) return 'EZE';
      if (this.brandObject.id === 3) return 'WD';
      if (this.brandObject.id === 6) return 'ECO';
      if (this.brandObject.id === 7) return 'ANLIN';
      if (this.brandObject.id === 8) return 'WWS';
      if (this.brandObject.id === 9) return 'MARTIN';
      return 'PGT';
    },
    full_wrapper_classes() {
      let res = 'wp-wrapper wtb-wrapper';

      if (this.brand === 4) {
        res = 'font-opensans wtb-wrapper';
      }

      if (this.append_classes) {
        res += ' ' + this.append_classes;
      }
      return res;
    },
    displayFooter() {
      return false;
    },
    brandBgColor() {
      if (this.brandObject.id === 4 || this.brandObject.id === 3) {
        return '#FFFFFF';
      }
      return '';
    },
    brandPrimaryColor() {
      let color = 'ezeprimary';
      switch (this.brand) {
        case 2:
          color = 'cgi';
          break;
        case 3:
          color = 'windoorGold';
          break;
        case 4:
        case 7:
          color = 'ezeprimary'
          break;
        case 6:
          color = 'ecoGreen'
          break;
        case 8:
          color = 'wws';
        case 9:
          color = 'martinDarkRed';
          break;
        default:
          color = 'pgt';
      }

      return color;
    },
    brandTitleColor() {
      switch (this.brand) {
          case 3:
              return 'black';
          case 8:
              return 'black';
          case 9:
              return 'martinDarkRed';
          default:
              return this.brandPrimaryColor;
      }
    },
    brandSecondaryColor() {
      let color = 'pgtGray';
      switch (this.brand) {
        case 2: // CGI
          color = 'cgi-dark-grey';
          break;
        case 3:
          color = 'windoor';
          break;
        case 4:
          color = 'eze'
          break;
        case 6:
          color = 'ecoDark'
          break;
        case 7:
          color = 'anlinBlue'
          break;
        case 9:
            color = 'martinDarkBlue'
            break;
        default:
          color = 'pgtGray';
      }

      return color;
    },

    brandPrimaryFontFamily() {
      let fontFamily = 'messinasans';
      switch (this.brand) {
        case 2:
          fontFamily = 'adellesans';
          break;
        case 3:
          fontFamily = 'gibson';
          break;
        case 4:
          fontFamily = 'opensans'
          break;
        case 6:
          fontFamily = 'ptsans'
          break;
        case 7:
          fontFamily = 'mont'
          break;
        default:
          fontFamily = 'messinasans';
      }

      return fontFamily;
    },

    perPage() {
      return this.innerWidth > 1024 ? 5 : 3;
    },
    brandShowGetQuoteButtonEnabled() {
        // WWS used to have the button disabled
        return true;
    }
  },

  watch: {
    dealers(newValue, oldValue) {
      this.setPages();
    }
  },

  methods: {
      scrollToTop() {
          window.scrollTo(0,0);
      },
    initGoogleApi(){
      if (typeof google !== "undefined" && google.maps && google.maps.places) {
        return;
      }
      let el = document.createElement('script');
      el.src = "https://maps.googleapis.com/maps/api/js?key=" + process.env.MIX_GMAP_API_KEY + "&libraries=places&language=en";
      document.body.appendChild(el);
    },
    sanitizeZipCode() {
      this.params.zipcode = this.params.zipcode.replace(/[A-z]/g, "");
    },
    submitSearch() {
      if (!this.params.zipcode) {
          this.has_errors = true;
          return true;
      }
      if (this.show_results === 'loading') return;
      this.show_results = "loading";
      if (this.search_status === 0) {
        if (this.params.international) {
          this.searchDealers(1);
        } else {
          this.fetchLatLong();
        }
      } else if (this.search_status === 1) {
        this.searchDealers(1);
      }
    },
    getUserZipCode() {
      if (!google) {
        return;
      }
      var _t = this;
      navigator.geolocation.getCurrentPosition((position) => {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        var point = new google.maps.LatLng(lat, lng);
        new google.maps.Geocoder().geocode(
          {
            latLng: point,
          },
          function (res, status) {
            if (status === "OK") {
              var address = res[0].formatted_address;
              var zip = address.match(/,\s\w{2}\s(\d{5})/);
              if (zip && zip[1]) {
                _t.params.zipcode = zip[1];
              }
            }
          }
        );
      });
    },
    hideUseMyLocationButton() {
      setTimeout(() => {
        this.showUseMyLocationButton = false;
      }, 200);
    },
    useMyLocation() {
      this.getUserZipCode();
    },
    fetchLatLong() {
      if (!this.params.zipcode) {
        this.has_errors = true;
        return true;
      }

      window.axios.defaults.headers.common = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      var map_url =
        "https://maps.googleapis.com/maps/api/geocode/json?key=" +
        process.env.MIX_GMAP_API_KEY +
        "&components=postal_code:" +
        this.params.zipcode;
      axios.get(map_url).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.results.length > 0) {
              this.no_results = false;
              var location = response.data.results[0].geometry.location;
              this.params.latitude = location.lat;
              this.params.longitude = location.lng;
              this.searchDealers(1);
            } else {
              this.no_results = true;
              this.show_results = "none";
              this.dealers = [];
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    searchDealers(search_type) {
      this.params.type = search_type;
      this.last_search_params = Object.assign({}, this.params);
      this.page = 1;
      axios.post(this.url_prepend + "/api/dealer/search", this.params).then(
        (response) => {
          this.dealers = response?.data?.dealers ?? [];
          if (this.dealers.length < 5 && (this.currentDistanceIndex  < this.distanceOptions().length - 1)) {
              this.currentDistanceIndex += 1;
              this.params.distance = this.distanceOptions()[this.currentDistanceIndex].id;
              return this.searchDealers(1);
          }
          this.scrollTo('#result-container');
          if (response.data.success && response.data.dealers && response.data.dealers.length) {
            this.show_results = "yes";
            this.show_distance = search_type == 1 ? 1 : 0;
            this.no_results = false;
            if (response.data.miles_input) {
                let miles_index = this.distances.find(e => e.id == response.data.miles_input);
                if (miles_index) this.params.distance = response.data.miles_input;
            }
          } else {
            this.no_results = true;
            this.show_results = "none";
            this.dealers = [];
            let distance_index = 0;
            this.currentDistanceIndex = distance_index;
            this.params.distance = this.distanceOptions()[distance_index].id;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    distanceOptions() {
      var distance = [
        { id: 5, value: "5 Miles"},
        { id: 15, value: "15 Miles" },
        { id: 20, value: "20 Miles" },
        { id: 25, value: "25 Miles" },
        { id: 30, value: "30 Miles" },
        { id: 50, value: "50 Miles" },
        { id: 100, value: "100 Miles" },
      ];

      return distance;
    },
    showQuotePopup(dealer) {

      let targetModalComponent = null;
      switch (this.brand) {
          case 4:
              targetModalComponent = EzeBreezeQuoteFormVue;
              break;
          case 9:
              targetModalComponent = MartinQuoteForm;
              break;
          default:
              targetModalComponent = QuoteStepFormRedesign;
            break;
      }

      this.$modal.show(
        targetModalComponent,
        {
          shownOnModal: true,
          dealer: dealer,
          brand: this.brand,
          brandObject: this.brandObject,
          brand_info: this.brandObject,
          brand_ga: this.brand_ga,
          domain: this.domain,
          service_types: this.service_types,
          home_types: this.home_types,
          new_buy_plans: this.new_buy_plans,
          window_amount: this.window_amount,
          door_amount: this.door_amount,
          window_importance: this.window_importance,
          interested_products: this.interested_products,
          lead_types: this.lead_types,
          business_types: this.business_types,
          project_conditions: this.project_conditions,
          project_types: this.project_types,
          states: this.states,
          show_close: true,
          interested_financing: this.interested_financing,
          redirect_thankyou: this.redirect_thankyou,
          countries: this.countries,
          default_country: this.default_country,
          data_form: "unique",
          frontend_url: this.frontend_url,
          add_wrapper: true,
          shoppingFor: this.shoppingFor,
          full_wrapper_classes: this.full_wrapper_classes,
          brandName: this.brandObject.name,
          homes_built_per_year: this.homes_built_per_year
        },
        {
          height: "auto",
          clickToClose: false,
          name: "quote_modal",
          adaptive: true,
          scrollable: true,
        }
      );

      setTimeout(() => {
        parent.postMessage({task: "scroll_top"}, "*");
        setTimeout(window.pgtAccomodatePopup, 300);
      }, 50);
    },
    showBusinessPopup() {
      this.$modal.show(BusinessContact);
    },
    initApiToken() {
      axios
        .post(this.url_prepend + "/api/token/request", {
          dealer_id: "general",
          secret: "general",
        })
        .then(
          (response) => {
            if (response.data.success) {
              console.log(response.data.message);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    geoLocationRequest() {
      // var $this = this;
      // var geoSuccess = function(position) {
      //     $this.params.latitude = position.coords.latitude;
      //     $this.params.longitude = position.coords.longitude;
      //     $this.searchDealers(0);
      // };
      // var geoError = function(error) {
      //     console.log(
      //         "Error occurred. Error code: " +
      //             error.code +
      //             ", Error message: " +
      //             error.message
      //     );
      // };
      //navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
    },
    scrollTo(elementId) {
      setTimeout(() => {
        const element = document.querySelector(elementId);
        element.scrollIntoView({behavior: "smooth", block: "start"});
      }, 100)
    },
    setPages () {
      this.pages = [];
      let numberOfPages = Math.ceil(this.dealers.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate (dealers) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return  dealers.slice(from, to);
    },
    previousDealersPage() {
      if (this.page > 1) {
        this.page--;
      }
    },
    nextDealersPage() {
        this.page++;
        this.scrollTo('#result-container');
    },

    enter(el, done) {
      gsap.fromTo(el, {
        autoAlpha: 0,
      }, {
        duration: 0.9,
        autoAlpha: 1,
        ease: Power3.easeIn,
        onComplete: done
      });
    },
    leave(el, done) {
      gsap.fromTo(el, {
        autoAlpha: 1,
        duration: 0,
      }, {
        duration: 0.45,
        autoAlpha: 0,
        ease: Power3.easeOut,
        onComplete: done
      });
    }


  },
}
</script>

<style scoped lang="scss">
.wtb-wrapper .dealer_weburl_approved a {
  display: block;
}

.wtb-wrapper .button_cont button {
  font-weight: 400;
  width: 100%;
  border-width: 0;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #f6b221;
  color: #003865;
  height: 57px;
  font-size: 21px;
  font-family: inherit;
}

.wtb-wrapper .button_cont button.prev_btn {
  float: left;
  font-weight: 300;
  background-color: #003865;
  color: #f6b221;
}

.wtb-wrapper .dealer_etp_status button,
.wtb-wrapper .dealer_etp_status a {
  width: 160px !important;
}

.wtb-wrapper .dealer_etp_status a {
  padding-top: 10px;
}

.wtb-wrapper .dealer-chat-online img {
  display: inline-block;
  width: 25px;
  height: 20px;
  margin-right: 5px;
}

#dyoZipRedesign {
  -webkit-appearance: button !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0;
}

#dyoFromRedesign {
  -webkit-appearance: button !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0;
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.underline {
  text-decoration: underline !important;
}

.finance_badge {
  margin: unset !important;
}

.wtb-wrapper .finance_badge {
  width: 120px;
}
.wtb-wrapper .finance_badge.martin-badge {
    width: 150px;
}

.dealer-listing-dealer {
  @media (max-width: 991px) {
    min-height: 250px;
  }
}

.dealer-list-name {
  font-size: 18px !important;
}

@media only screen and (max-width: 768px) {
  .wtb-md-hidden {
    display: none;
  }
}

#dealer-locator {
  $pgt-primary-color: #133A64;
  $pgt-dark-blue-color: #001A70;
  $pgt-primary-color-rgb: 19, 58, 100;
  $pgt-dark-blue-color-rgb: 0, 26, 112;
  $pgt-coral-color: #FF9F69;
  $pgt-dark-grey-color: #2B2E38;
  $pgt-light-grey-color: #ECEDED;
  $pgt-secondary-color: #343A40;
  $cgi-primary-color: #AF272F;
  $cgi-primary-color-rgb: 175, 39, 47;
  $eco-primary-color: #6DA947;
  $eco-primary-color-rgb: 109, 169, 71;
  $eco-hover-color: #93eb55;
  $wd-gold-color: #B9975B;
  $wd-gold-color-rgb: 185, 151, 91;
  $wd-dark-color: #1d1d1d;
  $eze-green-color: #47d7ac;
  $eze-blue-color: #326294;
  $eze-blue-color-rgb: 50, 98, 148;
  $anlin-blue-color: #006eb0;
  $anlin-blue-color-rgb: 0, 110, 176;
  $martin-primary-color: #901825;
  $martin-dark-blue-color: #051C2B;
  $martin-gray-color: #646565;
  $martin-primary-color-rgb: 144, 24, 37;
  $martin-dark-blue-color-rgb: 5, 28, 43;

  background-color: white;

  @media (max-width: 1024px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  @media (max-width: 425px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .text-base {
    font-size: 16px !important;
  }

  .border-pgt {
    --border-opacity: 1;
    border-color: $pgt-dark-blue-color !important;
    border-color: rgba(19, 58, 100, var(--border-opacity));
  }

  /** PGT */
  input.pgt-form-checkbox[type="checkbox"] {
    position: initial;
    height: unset;
    width: unset;
  }

  input.pgt-form-checkbox[type="checkbox"] {
    padding: 0 !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    display: inline-block !important;
    vertical-align: middle !important;
    background-origin: border-box !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    flex-shrink: 0 !important;
    height: 1.65rem !important;
    width: 1.65rem !important;
    color: $pgt-dark-blue-color !important;
    border-style: solid !important;
    border-width: 1px !important;
    background-color: #fff !important;
    border-radius: 0 !important;
    margin-top: 0 !important;
    cursor: pointer !important;
    opacity: 1 !important;
  }

  input.pgt-form-checkbox[type="checkbox"]:checked {
    border-color: transparent !important;
    background-color: currentColor !important;
    background-size: 100% 100% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  input.pgt-form-checkbox[type="checkbox"] + span {
    color: $pgt-dark-grey-color !important;
  }

  input.pgt-form-checkbox[type="checkbox"]:checked + span {
    color: $pgt-dark-grey-color !important;
  }

  .middleSectionTitle_PGT {
    font-size: 2.25rem !important;
    font-family: 'Messina Sans', Sans-serif;
    @media (max-width: 768px) {
      font-size: 32px !important;
      line-height: 40px !important;
    }
  }



  .middleSectionTitle_CGI {
    line-height: 40px !important;
  }

  .middleSectionTitle_CGI {
    font-family: Adelle, sans-serif !important;
    font-size: 2rem !important;
    letter-spacing: 4.8px !important;
  }

  .middleSectionTitle_ECO {
    font-family: 'Montserrat Light', sans-serif !important;
    color: #212A3C;
  }

  .middleSectionTitle_ANLIN {
    font-size: 2rem !important;

    @media (max-width: 768px) {
      font-size: 2.5rem !important;
    }
  }

  .middleSectionTitle_ANLIN {
    font-size: 2rem !important;
  }
  [data-forcestyle="single-dealer-address_PGT"] {
      text-transform: none;
  }
  a[data-forcestyle="submitSearch_PGT"] {
    border-radius: 50px;
    line-height: 1.6em !important;
    font-size: 16px !important;
    letter-spacing: 0.04em !important;
    font-weight: bold !important;
    text-transform: none;
  }
  a[data-forcestyle="submitSearch_CGI"] {
      letter-spacing: 1.2px !important;
      font-size: 1rem !important; // 16px
      text-transform: uppercase !important;
      color: #FFFFFF !important;
  }
  a[data-forcestyle="submitSearch_PGT"], a[data-forcestyle="submitSearch_CGI"] {
    font-weight: bold !important;
    text-decoration: none !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;

    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }

  a[data-forcestyle="submitSearch_ECO"] {
    font-family: 'Montserrat Light', sans-serif !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
    font-size: 1rem !important; // 16px
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;

    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }

  a[data-forcestyle="submitSearch_ECO"]:hover {
    background-color: $eco-hover-color !important;
  }

  a[data-forcestyle="submitSearch_MARTIN"] {
    border-radius: 0px;
    line-height: 1.6em !important;
    font-size: 16px !important;
    letter-spacing: 0.04em !important;
    font-weight: bold !important;
    text-transform: none;
	  background-color: $martin-primary-color !important;
  }

  // PGT
  [data-forcestyle="useMyLocation_PGT"] {
    border: 1px solid $pgt-dark-blue-color !important;
    line-height: 1.6em !important;
    font-size: 16px !important;
    letter-spacing: 0.04em !important;
    font-weight: bold !important;
    &:hover {
        @apply bg-pgt;
    }
  }

  // CGI
  [data-forcestyle="useMyLocation_CGI"] {
    line-height: inherit;
  }
  [data-forcestyle="useMyLocation_CGI"]:hover {
    background-color: $cgi-primary-color !important;
    color: white !important;
  }

  // WWS
  [data-forcestyle="useMyLocation_WWS"] {
    border: 1px solid #C62032 !important;
    color: #C62032 !important;
    background-color: #FFF !important;
  }

  [data-forcestyle="useMyLocation_EZE"] {
    line-height: 1.5 !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    letter-spacing: 1.2px !important;
    text-transform: uppercase !important;
    font-size: 12px !important;
    font-family: "NeutrafaceText", sans-serif !important;
  }

  [data-forcestyle="useMyLocation_ECO"]:hover {
    background-color: $eco-hover-color !important;
    color: white !important;
  }

  [data-forcestyle="useMyLocation_MARTIN"] {
    line-height: 1.6em !important;
    font-size: 16px !important;
    letter-spacing: 0.04em !important;
    font-weight: bold !important;
    border: none !important;
    background-color: $martin-primary-color !important;
    &:hover {
      background-color: $martin-dark-blue-color !important;
    }
  }

  // PGT
  [data-forcestyle="dealer-checkbox-label-span-PGT"] {
    margin-left: 16px !important;
    text-transform: capitalize;
  }

  // CGI
  [data-forcestyle="dealer-checkbox-label-span-CGI"] {
    margin-left: 16px !important;
  }

  // ECO
  [data-forcestyle="dealer-checkbox-label-span-ECO"] {
    margin-left: 16px !important;
    font-family: 'Montserrat Light', sans-serif !important;
    font-weight: 600 !important;
  }

  .ecoGreen-form-checkbox {
    outline: none !important;
  }

  [data-forcestyle="dealer-checkbox-label-span-MARTIN"] {
    margin-left: 16px !important;
	  font-family: 'Open Sans', sans-serif !important;
    text-transform: capitalize;
  }

  // All brands
  [data-forcestyle="googleMap"] {
    @media (max-width: 1023px) {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    @media (min-width: 1024px) {
      padding-left: 10rem !important;
      padding-right: 10rem !important;
    }
  }

  // All brands
  [data-forcestyle="ownResearchSection"] {
    @media (min-width: 1024px) {
      padding-left: 10rem !important;
      padding-right: 10rem !important;
    }
  }

  [data-forcestyle="change-criteria_PGT"], [data-forcestyle="change-criteria_CGI"], [data-forcestyle="change-criteria_ECO"] {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
  }

  [data-forcestyle="change-criteria_PGT"] {
      color: #2B2E38 !important;
  }

  [data-forcestyle="dl-showing-filters-title_PGT"] {
      color: #2B2E38 !important;
  }

  [data-forcestyle="dl-showing-filters-title_MARTIN"] {
    font-family: "Open Sans", sans-serif !important;
  }

  [data-forcestyle="change-criteria_MARTIN"] {
    font-family: "Open Sans", sans-serif !important;
    color: $martin-gray-color !important;
    font-size: 14px !important;
    border: none !important;
    &:hover {
      background-color: unset !important
    }
  }

  [data-forcestyle="change-criteria_ECO"] {
    font-family: 'Montserrat Light', sans-serif !important;
  }

  [data-forcestyle="dealer-address_PGT"],
  [data-forcestyle="dealer-address_CGI"],
  [data-forcestyle="dealer-address_ECO"],
  [data-forcestyle="dealer-address_WD"],
  [data-forcestyle="dealer-address_EZE"],
  [data-forcestyle="dealer-address_ANLIN"],
  [data-forcestyle="dealer-address_MARTIN"] {
    line-height: 1.5 !important;

    @media (max-width: 1024px) {
      font-size: 12px !important;
    }
  }

  [data-forcestyle="dealer-address_EZE"] {
    font-family: 'Trirong', 'Open Sans', sans-serif !important;
  }

  [data-forcestyle="dealer-address_MARTIN"] {
    font-family: 'Open Sans', sans-serif !important;
	  color: $martin-gray-color !important;
  }

  [data-forcestyle="desktop-single-dealer_MARTIN"] {
    background-color: #FFFFFF !important;
  }

  [data-forcestyle="desktop-single-dealer_PGT"], [data-forcestyle="desktop-single-dealer_CGI"] {
    @media (min-width: 1280px) {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    padding: 2rem 1rem !important;;
  }

  [data-forcestyle="single-dealer-chat-button_PGT"] {
    font-size: 14px;
    font-weight: bold !important;
    text-decoration: none !important;
    text-transform: none;
    letter-spacing: 1.2px;
    color: $pgt-dark-blue-color !important;
    background-color: $pgt-coral-color !important;
    border-color: $pgt-coral-color !important;
    border-radius: 50px !important;
  }

  [data-forcestyle="single-dealer-chat-button_CGI"] {
    letter-spacing: 2.8px;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none !important;
    color: $cgi-primary-color !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    line-height: 2.25rem !important;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 768px) {
      line-height: 3rem !important;
    }
  }

  [data-forcestyle="single-dealer-chat-button_ECO"] {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none !important;
    color: $eco-primary-color !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    font-family: 'Montserrat Light', sans-serif !important;
  }

  [data-forcestyle="single-dealer-chat-button_EZE"] {
    font-family: "NeutrafaceText", sans-serif !important;
    line-height: 1.5;
    border-radius: 0 !important;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    padding: 13px 16px 13px 16px;
  }


  [data-forcestyle="single-dealer-get-quote-button_PGT"] {
    font-size: 14px;
    font-weight: bold !important;
    letter-spacing: 1.2px;
    color: $pgt-dark-blue-color !important;
    background-color: $pgt-coral-color !important;
    border-color: $pgt-coral-color !important;
    border-radius: 50px !important;
    font-family: 'Messina Sans';
  }

  [data-forcestyle="single-dealer-get-quote-button_ANLIN"] {
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
    background-color: $anlin-blue-color !important;
  }

  [data-forcestyle="single-dealer-get-quote-button_EZE"] {
    line-height: 1.5 !important;
    font-family: 'NeutrafaceText', 'Open Sans', sans-serif !important;
  }

  [data-forcestyle="single-dealer-get-quote-button_MARTIN"] {
    font-size: 14px;
    font-weight: bold !important;
    letter-spacing: 1.2px;
    color: #FFFFFF !important;
    background-color: $martin-primary-color !important;
    border-color: $martin-primary-color !important;
    border-radius: 0 !important;
    font-family: "Open Sans", sans-serif !important;
    text-transform: none;
    line-height: unset !important;;
  }

  [data-forcestyle="single-dealer-chat-button_MARTIN"] {
    font-size: 14px;
    font-weight: bold !important;
    text-decoration: none !important;
    text-transform: none;
    letter-spacing: 1.2px;
    color: unset !important;
	  font-family: "Open Sans", sans-serif !important;
    background-color: $martin-dark-blue-color !important;
    border-color: $martin-dark-blue-color !important;
    border-radius: 0 !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
	  > div.text-martinDarkRed {
		  color: #FFFFFF !important;
	  }
  }

  [data-forcestyle="single-dealer-phone_PGT"],
  [data-forcestyle="single-dealer-phone_ECO"],
  [data-forcestyle="single-dealer-phone_WD"],
  [data-forcestyle="single-dealer-phone_EZE"],
  [data-forcestyle="single-dealer-phone_ANLIN"],
  [data-forcestyle="single-dealer-phone_MARTIN"] {
    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }

  [data-forcestyle="single-dealer-phone_EZE"] {
    align-items: center !important;
    font-family: 'NeutrafaceText', 'Open Sans', sans-serif !important;
  }

  [data-forcestyle="single-dealer-phone_CGI"] {
    @media (max-width: 768px) {
      line-height: 3rem !important;
      font-size: 12px !important;
    }
  }

  [data-forcestyle="single-dealer-phone_MARTIN"] {
    font-family: 'Open Sans', sans-serif !important;
  }

  [data-forcestyle="single-dealer-phone_ANLIN"] {
    @media (max-width: 768px) {
      border: 1px solid $anlin-blue-color !important;
    }
  }

  [data-forcestyle="single-dealer-phone-link_ECO"] {
    @media (min-width: 768px) {
      color: $eco-primary-color !important;
    }
  }

  [data-forcestyle="single-dealer-phone-link_WWS"] {
    color: #C62032 !important;
  }
  [data-forcestyle="single-dealer-phone_WWS"] {
      border-color: #C62032 !important;
  }
  [data-forcestyle="single-dealer-chat-button_WWS"] {
    border-color: #C62032 !important;
  }

  [data-forcestyle="single-dealer-get-quote-button_CGI"] {
    letter-spacing: 2.8px;
    font-size: 12px;
    line-height: 2.25rem !important;

    @media (max-width: 768px) {
      line-height: 3rem !important;
      //font-size: 12px !important;
    }
  }

  [data-forcestyle="single-dealer-get-quote-button_CGI"]:hover {
    background-color: transparent !important;
    border: 1px solid $cgi-primary-color !important;
    color: $cgi-primary-color !important;
  }


  [data-forcestyle="single-dealer-get-quote-button_ECO"] {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none !important;
    font-family: 'Montserrat Light', sans-serif !important;
  }

  [data-forcestyle="single-dealer-get-quote-button_ECO"]:hover {
    background-color: $eco-hover-color !important;
    border: 1px solid $eco-hover-color !important;
  }

  [data-forcestyle="single-dealer-get-quote-button_WWS"] {
    background-color: #C62032 !important;
    border: 1px solid #C62032 !important;
  }

  .bg-gray-50 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  [data-forcestyle="slideButton_PGT"] {
    display: flex;
    align-content: center;
    text-transform: uppercase !important;
    justify-content: center;
    color: #ffffff !important;
    background-color: #001A70 !important;
    border: 0 !important;
    font-size: 14px !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;

    @media (min-width: 768px) {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }

    svg path {
      color: #ffffff !important;
    }
  }

  [data-forcestyle="slideButton_CGI"] {
    display: flex;
    text-transform: uppercase !important;
    align-content: center;
    justify-content: center;
    color: #ffffff !important;
    background-color: $cgi-primary-color !important;
    border: 0 !important;
    font-size: 14px !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;

    @media (min-width: 768px) {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }

    svg {
      height: 100%;
    }
    svg path {
      color: #ffffff !important;
    }
  }

  [data-forcestyle="slideButton_ECO"] {
    display: flex;
    align-content: center;
    justify-content: center;
    color: #ffffff !important;
    background-color: $eco-primary-color !important;
    border: 0 !important;
    font-size: 14px !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none !important;
    border-radius: 0 !important;
    font-family: 'Montserrat Light', sans-serif !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;

    @media (min-width: 768px) {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }

    svg {
      height: 100%;
    }
    svg path {
      color: #ffffff !important;
    }
  }

  [data-forcestyle="slideButton_WD"] {
    display: flex;
    align-content: center;
    justify-content: center;
    color: $wd-dark-color !important;
    background-color: $wd-gold-color !important;
    border: 0 !important;
    font-size: 14px !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    text-transform: uppercase !important;
    font-family: 'Montserrat Light', sans-serif !important;
    letter-spacing: 6px !important;

    svg {
      width: 2rem !important;
    }

    svg path {
      color: $wd-dark-color !important;
    }
  }

  [data-forcestyle="slideButton_EZE"] {
    display: flex;
    align-content: center;
    justify-content: center;
    color: #ffffff !important;
    background-color: $eze-blue-color !important;
    border: 0 !important;
    font-size: 14px !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    border-radius: 0.25rem !important;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;

    svg {
      width: 2rem !important;
    }

    svg path {
      color: #ffffff !important;
    }
  }

  [data-forcestyle="slideButton_ANLIN"] {
    display: flex;
    align-content: center;
    justify-content: center;
    color: #ffffff !important;
    background-color: $anlin-blue-color !important;
    border: 0 !important;
    font-size: 14px !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    border-radius: 0.25rem !important;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;

    svg {
      width: 2rem !important;
    }
    svg path {
      color: #ffffff !important;
    }
  }

  [data-forcestyle="slideButton_MARTIN"] {
	  font-family: "Open Sans", sans-serif !important;
    display: flex;
    align-content: center;
    text-transform: unset !important;
    justify-content: center;
    color: #ffffff !important;
    background-color: $martin-primary-color !important;
    border: 0 !important;
    font-size: 14px !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;

    @media (min-width: 768px) {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }

    svg path {
      color: #ffffff !important;
    }
  }

  [data-forcestyle="slideButton_WWS"] {
    min-height: 40px;
    border: 2px solid #C62032;
    color: #000 !important;
      svg path {
          color: #000 !important;
      }
      &:hover {
          outline-color: transparent !important;
      }
  }

  .border-cgi {
    --border-opacity: 1;
    border-color: $cgi-primary-color !important;
    border-color: rgba(175, 39, 47, var(--border-opacity)) !important;
  }


  [data-forcestyle="results"] {
    @media (max-width: 1023px) {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    @media (min-width: 1024px) {
      padding-left: 10rem !important;
      padding-right: 10rem !important;
    }
  }

  [data-forcestyle="copy-text_PGT"],
  [data-forcestyle="copy-text_CGI"],
  [data-forcestyle="copy-text_ECO"],
  [data-forcestyle="copy-text_WD"],
  [data-forcestyle="copy-text_EZE"],
  [data-forcestyle="copy-text_ANLIN"],
  [data-forcestyle="copy-text_WWS"],
  [data-forcestyle="copy-text_MARTIN"],{
    @media (max-width: 1024px) {
      font-size: 16px !important;
    }
  }

  [data-forcestyle="copy-text_PGT"] {
      font-family: 'Messina Sans', Sans-serif;
      color: #2B2E38 !important;
      font-size: 1.125rem !important;
  }

  [data-forcestyle="copy-text_EZE"] {
    font-family: "Trirong", sans-serif !important;
  }

  [data-forcestyle="copy-text_WD"].own-research-body-copy {
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 1.5;
  }
  [data-forcestyle="copy-text_WD"]{
      .own-research-field-label {
          text-transform: uppercase;
      }
  }

  [data-forcestyle="copy-text_WWS"] {
      color: #6F727B !important;
  }

  [data-forcestyle="copy-text_ECO"] {
    font-family: "adelle-sans", sans-serif !important;
    color: #212A3C !important;
  }

  [data-forcestyle="copy-text_MARTIN"] {
    font-family: 'Open Sans', sans-serif !important;
	  color: $martin-gray-color !important;
    font-size: 1.125rem !important;
  }

  [data-forcestyle="no-results-header_ECO"] {
    font-family: 'Montserrat Light', sans-serif !important;
  }

  [data-forcestyle="no-results-header_MARTIN"] {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.125rem !important;
  }

  [data-forcestyle="no-results-header_ANLIN"] {
    @media (max-width: 1024px) {
      font-size: 16px !important;
    }
  }

  [data-forcestyle="no-results-header_EZE"] {
    font-family: "NeutrafaceText", 'Open Sans', sans-serif;
    @media (max-width: 1024px) {
      font-size: 16px !important;
    }
  }


  [data-forcestyle="copy-text_EZE"] {
    font-family: "Trirong", "Open Sans", sans-serif !important;
  }

  [data-forcestyle="single-dealer-name_PGT"] {
    color: $pgt-dark-blue-color !important;
    a {
      text-decoration: none !important;
      color: $pgt-dark-blue-color !important;
    }
  }

  [data-forcestyle="single-dealer-name_CGI"] {
    color: $cgi-primary-color-rgb !important;
    a {
      text-decoration: none !important;
      color: $cgi-primary-color-rgb !important;
    }
  }

  [data-forcestyle="single-dealer-name_ECO"] {
    font-family: 'Montserrat Light', sans-serif !important;
    color: $eco-primary-color !important;

    a {
      text-decoration: none !important;
      color: $eco-primary-color !important;
    }
  }

  [data-forcestyle="single-dealer-name_EZE"] {
    font-family: "NeutrafaceText", "Open Sans", sans-serif !important;
    color: $eze-blue-color !important;
    a {
      text-decoration: none !important;
      color: $eze-blue-color !important;
    }
  }

  [data-forcestyle="single-dealer-name_WWS"] {
    color: #000 !important;
    span {
        color: #000 !important;
    }
    a {
      text-decoration: none !important;
    }
  }


  [data-forcestyle="single-dealer-name_MARTIN"] {
    color: $martin-primary-color !important;
	  font-family: "Open Sans", sans-serif !important;
	  font-weight: bold !important;
    a {
      text-decoration: none !important;
      color: $martin-primary-color !important;
    }
  }

  [data-forcestyle="dealer-speaks-spanish_PGT"] {
    color: darkgray !important;
  }
  [data-forcestyle="dealer-speaks-spanish_WWS"] {
    color: #6F727B !important;
  }
  [data-forcestyle="dealer-speaks-spanish_MARTIN"] {
    color: $martin-primary-color !important;
    font-family: "Open Sans", sans-serif !important;
  }

  .mb-4 {
    margin-bottom: 1rem !important;
  }

  .my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  @media (min-width: 768px) {
    .md\:px-10 {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;
    }

    .md\:py-10 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
  }

  .px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .text-gray-600 {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  //[data-forcestyle="single-dealer-container_PGT"]:not(:last-child) {
  //  background-image: -webkit-linear-gradient(180deg, red 50%, transparent 50%) !important;
  //  background-image:    -moz-linear-gradient(180deg, red 50%, transparent 50%) !important;
  //  background-image:     -ms-linear-gradient(180deg, red 50%, transparent 50%) !important;
  //  background-image:      -o-linear-gradient(180deg, red 50%, transparent 50%) !important;
  //  background-image:         linear-gradient(180deg, red 50%, transparent 50%) !important;
  //  background-position: 50% 100%, 0 0 !important;
  //  background-repeat: no-repeat !important;
  //  background-size: 50% 5px !important;
  //}

   [data-forcestyle="single-dealer-container_PGT"]:not(:last-child) {
    --border-opacity: 80;
    border-bottom: .7px solid rgba($pgt-dark-blue-color-rgb, var(--border-opacity));
    background-clip: padding-box;
  }

  [data-forcestyle="single-dealer-container_CGI"]:not(:last-child) {
    --border-opacity: 80;
    border-bottom: .7px solid rgba($cgi-primary-color-rgb, var(--border-opacity));
    background-clip: padding-box;
  }

  [data-forcestyle="single-dealer-container_ECO"]:not(:last-child) {
    --border-opacity: 80;
    border-bottom: .7px solid rgba($eco-primary-color-rgb, var(--border-opacity));
    background-clip: padding-box;
  }

  [data-forcestyle="single-dealer-container_WD"]:not(:last-child) {
    --border-opacity: 80;
    border-bottom: .7px solid rgba($wd-gold-color-rgb, var(--border-opacity));
    background-clip: padding-box;
  }

  [data-forcestyle="single-dealer-container_EZE"]:not(:last-child) {
    --border-opacity: 80;
    border-bottom: .7px solid rgba($eze-blue-color-rgb, var(--border-opacity));
    background-clip: padding-box;
  }

  [data-forcestyle="single-dealer-container_ANLIN"]:not(:last-child) {
    --border-opacity: 80;
    border-bottom: .7px solid rgba($anlin-blue-color-rgb, var(--border-opacity));
    background-clip: padding-box;
  }

  [data-forcestyle="single-dealer-container_WWS"]:not(:last-child) {
    --border-opacity: 80;
    border-opacity: 80;
    border-bottom: .7px solid #C62032;
    background-clip: padding-box;
  }

  [data-forcestyle="single-dealer-container_MARTIN"]:not(:last-child) {
    --border-opacity: 80;
    border-bottom: .7px solid rgba($martin-dark-blue-color-rgb, var(--border-opacity));
    background-clip: padding-box;
  }

  .see-more-text {
    width: 100%;
    text-align: center;
    margin-right: 2px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    line-height: 0 !important;
  }
  [data-forcestyle="zipinput_PGT"] {
      color: $pgt-dark-blue-color;
  }
  [data-forceStyle="zipinput_EZE"] {
      font-family: "NeutrafaceText", "Open Sans", sans-serif !important;
      color: #326295;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1.2rem !important;
  }

  [data-forcestyle="fromselect_EZE"] {
    font-family: "NeutrafaceText", "Open Sans", sans-serif !important;
    color: #326295;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem !important;
  }

  [data-forcestyle="fromselect_MARTIN"] {
    font-family: "Open Sans", sans-serif !important;
    color: $martin-dark-blue-color;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem !important;
	  border-color: $martin-dark-blue-color !important;
  }

  [data-forceStyle="zipinput_MARTIN"] {
    font-family: "Open Sans", sans-serif !important;
    color: $martin-dark-blue-color;
    border-color: $martin-dark-blue-color !important;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem !important;
  }

  [data-forceStyle="zipinput_MARTIN"]::placeholder {
    color: $martin-gray-color !important;
    opacity: 0.7 !important;
  }
  [data-forceStyle="zipinput_MARTIN"]::-ms-input-placeholder { /* Microsoft Edge */
    color: $martin-gray-color !important;
    opacity: 0.7 !important;
  }

  [data-forcestyle="dl-showing-filters-title_EZE"] {
     font-family: 'Trirong', sans-serif !important;
  }

  [data-forcestyle="dl-selected-filter_EZE"] {
    font-family: 'Trirong', sans-serif !important;
  }

  [data-forcestyle="dl-selected-filter_MARTIN"] {
    font-family: 'Open Sans', sans-serif !important;
    color: $martin-primary-color !important
  }

  .middleSectionTitle_MARTIN {
    font-size: 2.25rem !important;
    font-family: 'Open Sans', Sans-serif;
    @media (max-width: 768px) {
      font-size: 32px !important;
      line-height: 40px !important;
    }
  }

  /** PGT */
  input.martinDarkRed-form-checkbox[type="checkbox"] {
    position: initial;
    height: unset;
    width: unset;
  }

  input.martinDarkRed-form-checkbox[type="checkbox"] {
    padding: 0 !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    display: inline-block !important;
    vertical-align: middle !important;
    background-origin: border-box !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    flex-shrink: 0 !important;
    height: 1.65rem !important;
    width: 1.65rem !important;
    color: $martin-dark-blue-color !important;
    border-color: $martin-dark-blue-color !important;
    border-style: solid !important;
    border-width: 1px !important;
    background-color: #fff !important;
    border-radius: 0 !important;
    margin-top: 0 !important;
    cursor: pointer !important;
    opacity: 1 !important;
  }

  input.martinDarkRed-form-checkbox[type="checkbox"]:checked {
    border-color: transparent !important;
    background-color: $martin-primary-color !important;
    background-size: 100% 100% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  input.martinDarkRed-form-checkbox[type="checkbox"] + span {
    color: $martin-dark-blue-color !important;
  }

  input.martinDarkRed-form-checkbox[type="checkbox"]:checked + span {
    color: $martin-primary-color !important;
  }

}
</style>
